import { useEffect } from 'react';

const MobileDisplay = () => {
  useEffect(() => {
    if (window) {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }, []);

  return (
    <>
      <ins
        className="adsbygoogle"
        style={{
          display: 'inline-block',
          width: '320px',
          height: '100px',
        }}
        data-ad-client="ca-pub-1919598055512436"
        data-ad-slot="7915791456"
      ></ins>
    </>
  );
};

export default MobileDisplay;
