export const question = [
  // 목적 문항
  "반려식물이 필요한 이유가 무엇인가요?",
  // 유의점 문항
  "반려동물과 함께 생활하고 있나요?",
  "어린 아이와 함께 생활하고 있나요?",
  // 식물 지식/경험 문항
  "분갈이를 할 수 있거나, 분갈이를 해본 적이 있나요?",
  "현재 식물을 기르고 있거나, 식물을 길러본 적이 있나요?",
  "알고 있는 식물 이름이 3가지 이상인가요?",
  "식물에 따라 기르는 방법이 다르다는 걸 알고 있나요?",
  "깍지벌레, 곰팡이 등 병충해로 식물이 아플 수 있다는 걸 알고 있나요?",
  "원예 용품(비료, 살충제, 분무기 등)을 구매한 적이 있나요?",
  // 식물 성장 환경 문항
  "햇빛이 잘 들어오는 양지가 있나요?",
  "공기 순환이 잘되는 환경인가요?",
  "겨울에도 10°C 이상 온도가 유지되는 온화한 곳이 있나요?",
  "너무 건조하지 않은 촉촉한 환경인가요?",
  "주기적으로 물을 주실 수 있나요?",
  "책상이나 베란다 등 식물을 둘만한 공간이 있나요?",
];
