export const result = {
  // 목적 Plan(T)erior or (H)ealth or (E)motion
  // 반려동물 (C)are or (D)on't care
  // 경험 (N)ewbie or (P)ro
  // 환경 (G)ood or (B)ad
  TCNB: {
    name: "몬스테라",
    imgUrl: "img/1monstera.png",
    feature1: "플랜테리어의 선구 식물",
    feature2: "가습효과가 뛰어나고 공기정화도 일품",
    feature3: "뽕뽕 잎에 구멍이 뚫려서 키우는 재미 만점",
  },
  HCNB: {
    name: "스투키",
    imgUrl: "img/2stucky.png",
    feature1: "강인한 생명력으로 별다른 돌봄 없이도 자생 가능",
    feature2: "뛰어난 공기정화 능력",
    feature3: "초보 집사 및 바쁜 현대인에게 강추",
  },
  ECNB: {
    name: "아글라오네마",
    imgUrl: "img/3aglaonema.png",
    feature1: "영화 '레옹'에 등장해 '레옹식물'이라는 애칭을 지님",
    feature2: "공기 중 오염 물질 정화 능력 탁월",
    feature3: "부귀, 행복, 행운의 꽃말을 지님",
  },
  TDNB: {
    name: "여인초",
    imgUrl: "img/4birdParadise.png",
    feature1: "열대 우림의 이국적 분위기를 자아내는 식물",
    feature2: "시원하게 쭉 뻗은 줄기와 초록 잎이 매력적",
    feature3: "실내 적응이 뛰어나고 병충해가 드물어 초보집사에게 강추",
  },
  HDNB: {
    name: "필로덴드론",
    imgUrl: "img/5philodendron.png",
    feature1: "이색적인 실내 분위기 연출 가능",
    feature2: "음이온을 방출하는 대표적인 실내 공기정화 식물",
    feature3: "가지치기를 할 때 나오는 액체는 독성이 있어 주의",
  },
  EDNB: {
    name: "마리모",
    imgUrl: "img/6marimo.png",
    feature1: "반려식물 열풍을 일으켰던 그 '마리모'",
    feature2: "둥글게 뭉쳐 자라며 테스트공만해지기까지 7년 소요",
    feature3: "영원한 사랑'이라는 의미를 담고 있음",
  },
  TCPB: {
    name: "드라세나 맛상게아나",
    imgUrl: "img/7dracaena.png",
    feature1: "'행운목'이라는 별칭으로 불림",
    feature2: "대나무 같은 줄기 끝으로 길쭉한 잎이 시원해 보임",
    feature3: "뛰어난 실내 적응력",
  },
  HCPB: {
    name: "스킨답서스",
    imgUrl: "img/8goldenPothos.png",
    feature1: "식물계의 튼튼 no.1",
    feature2: "짱짱한 일산화탄소 제거능력",
    feature3: "수경재배 가능",
  },
  ECPB: {
    name: "안스리움 안드레아눔",
    imgUrl: "img/9anthurium.png",
    feature1: "조화로 자주 오해받는 윤기나는 빨강 식물",
    feature2: "매력적인 잎맥",
    feature3: "꽃말은 '사랑의 번뇌', '순수한 마음'",
  },
  TDPB: {
    name: "크로톤",
    imgUrl: "img/10croton.png",
    feature1: "태양을 닮은 식물",
    feature2: "알록달록 화려한 잎 자랑",
    feature3: "햇빛을 받으면 받을수록 잎이 예쁘게 유지됨",
  },
  HDPB: {
    name: "아가베 아테누아타",
    imgUrl: "img/11agave.png",
    feature1: "100년에 딱 한 번 꽃을 피우는 세기의 식물",
    feature2: "꽃이 용의 혀를 닮아 '용설란'이라고도 불림",
    feature3: "멕시코가 원산지인 다육식물",
  },
  EDPB: {
    name: "축전",
    imgUrl: "img/12conophytu.png",
    feature1: "앙증맞은 하트 모양 식물",
    feature2: "코노피튬속 다육식물",
    feature3: "고온다습한 여름에는 성장을 멈추고 휴면",
  },
  TCNG: {
    name: "제라늄",
    imgUrl: "img/13pelagonium.png",
    feature1: "매력적인 연중개화 반려식물",
    feature2: "화려한 겹꽃을 피움",
    feature3: "햇빛만 보장된다면 생명력이 강한 꽃식물",
  },
  HCNG: {
    name: "유칼립투스 구니",
    imgUrl: "img/14eucalyptus.png",
    feature1: "시원한 향을 뿜으며 독보적인 청량감을 자랑하는 식물",
    feature2: "호흡기 질환에 효능이 좋음",
    feature3: "쑥쑥 잘 자라서 야외에서 키울 시 1년에 2m 이상으로 크기도 함",
  },
  ECNG: {
    name: "라벤더",
    imgUrl: "img/15lavender.png",
    feature1: "향기로운 보라색 꽃을 지닌 힐링과 치유의 식물",
    feature2: "진통, 정신 안정, 방충, 살균 등에 효능이 좋음",
    feature3: "꽃말은 '정절'과 '침묵'",
  },
  TDNG: {
    name: "사계귤(유주나무)",
    imgUrl: "img/16calamondin.png",
    feature1: "사계절 꽃과 열매를 맺으며 상큼한 시트러스 향을 뿜뿜함 ",
    feature2: "열매는 관상용으로만..깜짝놀랄만큼 시큼한 맛",
    feature3: "열매를 맺는 유실수라서 햇빛은 필수",
  },
  HDNG: {
    name: "해피트리",
    imgUrl: "img/17radermachera.png",
    feature1: "행복나무', '부귀나무', '대박나무'의 별칭으로 불림",
    feature2: "습도 조절과 공기 정화 능력이 뛰어남",
    feature3: "꽃말이 ' 행복가득', '부귀가득'으로 개업 선물로 애용됨",
  },
  EDNG: {
    name: "레몬밤",
    imgUrl: "img/18lemonBalm.png",
    feature1: "우울증, 불안, 스트레스 해소에 탁월한 허브",
    feature2: "레몬밤 다이어트'로 이름 좀 날림",
    feature3:
      "과다하게 섭취하면 메스꺼움, 구토, 현기증, 과도한 졸음이 유발될 수 있으니 주의",
  },
  TCPG: {
    name: "알로카시아 아마조니카",
    imgUrl: "img/19alocasia.png",
    feature1: "거북이의 등껍질을 닮음",
    feature2: "세련되고 선명한 잎맥을 자랑함",
    feature3: "과습하면 잎이 타들어갈 수 있으니 주의",
  },
  HCPG: {
    name: "뱅갈 고무나무",
    imgUrl: "img/20benghalensis.png",
    feature1: "모던한 느낌으로 존재감 뿜뿜하는 고무나무",
    feature2: "갑자기 환경이 바뀌면 잎이 후두둑 떨어지며 예민하게 반응",
    feature3:
      "나무에 상처가 나면 독성 있는 흰 액체가 나오는데 알러지 반응을 일으킬 수 있으니 주의",
  },
  ECPG: {
    name: "율마(월마)",
    imgUrl: "img/21monterey.png",
    feature1:
      "연두한 빛깔과 상쾌한 향기를 지녀 존재 자체만으로도 기분을 업시킴",
    feature2:
      "피톤치트를 뿜뿜해 스트레스 저하, 면역력 증진, 심폐기능 강화, 심리 안정 효능 탁월",
    feature3: "햇빛과 바람이 꼭꼭 필요",
  },
  TDPG: {
    name: "박쥐란",
    imgUrl: "img/22staghorn.png",
    feature1: "잎 모양이 박쥐를 닮아 박쥐란이라는 이름이 붙음",
    feature2: "사슴 머리 플랜테리어로 유명",
    feature3: "흙이 따로 필요 없는 에어플랜트(Air Plant) 식물",
  },
  HDPG: {
    name: "파키라",
    imgUrl: "img/23pachira.png",
    feature1: "굵은 목대와 손가락처럼 갈라진 잎으로 독특한 외형을 자랑",
    feature2: "초미세먼지 제거에 가장 효과적인 식물",
    feature3: "꽃말은 '행운', '관용'",
  },
  EDPG: {
    name: "로즈마리",
    imgUrl: "img/24rosemary.png",
    feature1: "라틴어로 '바다의 이슬'이라는 뜻을 지닌 바늘 모양 잎의 허브",
    feature2: "천식, 피부보호, 항암효과에 효능이 좋음",
    feature3: "로즈마리 차를 너무 많이 섭취할 시 복통, 구토 등의 부작용 주의",
  },
};
